import { ColorType } from '@mm-ui/components/lib/components/icon/color.type';
import { Color } from '@fnc-shared/constants/color';
import { PLCStatus } from '@fnc-app/modules/replenishment/state/replenishment.model';

export type ProductLifeCycle = Record<string, CountryStatus[]>;
export interface CountryStatus {
  platform: PLCPlatforms;
  currentIntention: Intention;
  nextIntention?: Intention;
}

interface Intention {
  status: PLCStatuses;
  motive: PLCIntentionMotive;
  comment: string;
  dueAt: string;
}
export interface TabsData {
  name: string;
  label: string;
  iconKind: string;
  iconColor: string;
}

export enum PLCStatuses {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DELIST = 'delist',
  UNDECIDED = 'undecided'
}

export enum PLCSTATUSCOLORS {
  ACTIVE = 'green',
  INACTIVE = 'red',
  AMBER = 'orange'
}

export const PLCStatusColors: Record<PLCStatus, ColorType> = {
  [PLCStatus.ACTIVE]: Color.GREEN,
  [PLCStatus.INACTIVE]: Color.RED,
  [PLCStatus.MIXED]: Color.ORANGE
};

export enum PlatformIcons {
  AMAZON = 'amazon',
  MARKETPLACE = 'metro'
}

export enum PLCPlatforms {
  AMAZON = 'amazon',
  MARKETPLACE = 'marketplace'
}

export enum PLCIntentionMotive {
  DEFAULT_INTENTION = 'DEFAULT_INTENTION',
  BAD_PERFORMANCE = 'BAD_PERFORMANCE',
  DISCONTINUED_BY_MANUFACTURER = 'DISCONTINUED_BY_MANUFACTURER',
  WRONG_PRODUCT_OR_OFFER_DATA = 'WRONG_PRODUCT_OR_OFFER_DATA',
  QUALITY_ISSUE = 'QUALITY_ISSUE',
  NOT_PART_OF_CHANNEL_ASSORTMENT = 'NOT_PART_OF_CHANNEL_ASSORTMENT',
  OUT_OF_SEASON = 'OUT_OF_SEASON'
}
