import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PLCStatusColors } from '../plc-status-modal/plc-modal.constants';
import { PLCStatusMultiStep, PLCStatusMultiStepWarning } from './plc-status-multi-step.model';
import { DataLayerData } from '@fnc-app/modules/core/services/google-analytics/google-analytics.interfaces';
import { PLCStatus } from '@fnc-app/modules/replenishment/state/replenishment.model';
import { Color } from '@fnc-shared/constants/color';
import { DASH_SYMBOL } from '@fnc-shared/constants/symbols';

@Component({
  selector: 'fnc-plc-status-multi-step',
  templateUrl: './plc-status-multi-step.component.html',
  styleUrls: ['./plc-status-multi-step.component.scss']
})
export class PlcStatusMultiStepComponent implements OnChanges {
  readonly dashSymbol = DASH_SYMBOL;

  @Input() plcStatuses: PLCStatusMultiStep[] = [];
  @Output() checkStatus: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  @ViewChild('plcStatusWarningInfoToolTipTemplate', { static: true }) plcStatusWarningInfoToolTipTemplate: TemplateRef<ImplicitAny>;

  plcStatusWarning: PLCStatusMultiStepWarning | null;

  componentDataLayerData: DataLayerData;

  constructor(private readonly translateService: TranslateService) {}

  getStatusIcon(status?: PLCStatus) {
    return PLCStatusColors[status];
  }

  checkStatusAction(event: MouseEvent) {
    this.checkStatus.emit(event);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.plcStatuses) {
      this.checkPlcStatusWarning();
    }
  }

  checkPlcStatusWarning() {
    if (!this.plcStatuses || this.plcStatuses.length === 0) {
      this.plcStatusWarning = null;
    } else {
      const preferredInventory = this.plcStatuses[0];
      const remainingStatuses = this.plcStatuses.slice(1);
      switch (preferredInventory.status) {
        case PLCStatus.ACTIVE:
          this.plcStatusWarning = null;
          break;
        case PLCStatus.INACTIVE:
          if (remainingStatuses.filter(plcStatus => plcStatus.status !== PLCStatus.INACTIVE).length) {
            this.plcStatusWarning = {
              color: Color.ORANGE,
              message: this.translateService.instant('SUPPLY_CHAIN.REPLENISHMENT.TOOLTIPS.PREFERRED_INVENTORY_WARNING') as string
            };
          } else {
            this.plcStatusWarning = {
              color: Color.RED,
              message: this.translateService.instant('SUPPLY_CHAIN.REPLENISHMENT.TOOLTIPS.PREFERRED_INVENTORY_ERROR') as string
            };
          }
          break;
        case PLCStatus.MIXED:
          this.plcStatusWarning = {
            color: Color.ORANGE,
            message: this.translateService.instant('SUPPLY_CHAIN.REPLENISHMENT.TOOLTIPS.PREFERRED_INVENTORY_WARNING') as string
          };
          break;
      }
    }
  }

  isElementPreferredInventory(elementIndex: number) {
    return this.plcStatuses?.length > 0 && elementIndex === 0;
  }
}
