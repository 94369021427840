export enum FilterNames {
  ACCESS = 'ACCESS',
  ACCOUNTING_PERIOD_NUMBER = 'ACCOUNTING_PERIOD_NUMBER',
  ACTIVE = 'ACTIVE',
  APPROVED = 'APPROVED', // old APPROVE_STATUS
  ASSORTMENT = 'IS_BLOCKED',
  ASSORTMENT_NEW = 'ASSORTMENT',
  AVAILABILITY = 'AVAILABILITY',
  BLOCKED_REASONS = 'BLOCKED_REASONS',
  BOOK = 'BOOK',
  BOOKED_AT = 'BOOKED_AT',
  BRAND = 'BRANDS',
  BRAND_IDS = 'BRAND_IDS',
  BUYER_TYPE = 'BUYER_TYPE',
  CATEGORIES = 'CATEGORIES',
  CLASSIFICATION = 'CLASSIFICATION',
  CLEARED = 'CLEARED', // old CLEARING_STATUS
  CURRENT_PLC = 'currentIntention',
  COMMENTS_PRESENCE = 'COMMENTS_PRESENCE',
  COMMITMENT = 'COMMITMENT',
  COUNTRY = 'COUNTRY',
  CREATED = 'CREATED',
  CREATED_AT = 'CREATED_AT',
  CREATED_BY = 'CREATED_BY',
  DATE = 'DATE',
  DATE_VALUE = 'DATE_VALUE',
  DELIVERY_INVENTORY = 'DELIVERY_INVENTORY',
  DELIVERY_TYPE = 'DELIVERY_TYPE',
  DESTINATION_COUNTRY = 'DESTINATION_COUNTRY',
  EXPECTED_CREDIT_NOTE_STATUS = 'EXPECTED_CREDIT_NOTE_STATUS',
  DOCUMENT_ID = 'DOCUMENT_ID',
  DUE_DATE = 'DUE_DATE',
  DUE_DAYS = 'DUE_DAYS',
  ERROR_TYPE = 'ERROR_TYPE',
  ETA = 'ETA',
  E_INVOICE_STATUS = 'E_INVOICE_STATUS',
  FILE_UPLOADED = 'FILE_UPLOADED',
  FROM_ID = 'FROM_ID',
  FULFILLMENT_ORDER_TYPE = 'FULFILLMENT_ORDER_TYPE',
  GTINS = 'GTINS',
  HAS_ERRORS = 'HAS_ERRORS',
  HAS_WARNINGS = 'HAS_WARNINGS',
  HISTORICAL = 'HISTORICAL',
  ID = 'ID',
  INTENTIONS = 'INTENTIONS',
  INVENTORY = 'INVENTORY',
  INVENTORY_IDS = 'INVENTORY_IDS',
  INVENTORY_TO = 'INVENTORY_TO',
  INVENTORY_TYPE = 'INVENTORY_TYPE',
  INVOICE_GENERATION_STATUS = 'INVOICE_GENERATION_STATUS',
  INVOICE_TYPE = 'INVOICE_TYPE',
  IS_AUTOMATIC = 'IS_AUTOMATIC',
  IS_AUTO_APPROVAL = 'IS_AUTO_APPROVAL',
  IS_AUTO_CLEARING = 'IS_AUTO_CLEARING',
  IS_AUTO_SOURCE_TYPE = 'IS_AUTO_SOURCE_TYPE',
  IS_COMMITMENT_OPEN = 'IS_COMMITMENT_OPEN',
  IS_CORRECTED = 'IS_CORRECTED',
  IS_IMPORTANT = 'IS_IMPORTANT',
  IS_INVOICE_CREATED = 'IS_INVOICE_CREATED',
  IS_IWT = 'IS_IWT',
  IS_LATE_FEE_INCLUDED = 'IS_LATE_FEE_INCLUDED',
  IS_LATE_ORDER = 'IS_LATE_ORDER',
  IS_OPEN = 'IS_OPEN',
  IS_OUT_OF_STOCK = 'IS_OUT_OF_STOCK',
  IS_STOCK_IN_TRANSIT = 'IS_STOCK_IN_TRANSIT',
  ITEM_CATEGORY_IDS = 'ITEM_CATEGORY_IDS',
  LABELS = 'LABELS',
  MARKETPLACE_IDS = 'MARKETPLACE_IDS',
  MESSAGE_DIRECTION = 'MESSAGE_DIRECTION',
  MESSAGE_SOURCE = 'MESSAGE_SOURCE',
  NOTE_REASON = 'NOTE_REASON',
  NOTE_TYPE = 'NOTE_TYPE',
  ORDER_ID = 'ORDER_ID',
  OWN_BRAND = 'OWN_BRAND',
  PAID = 'PAID', // old PAID_STATUS
  PAID_DATE = 'PAID_DATE',
  PAYMENTS_BLOCKED = 'PAYMENTS_BLOCKED',
  PAYMENTS_SETTINGS = 'PAYMENTS_SETTINGS',
  PAYMENT_SYSTEM = 'PAYMENT_SYSTEM',
  PLANNED_AT = 'PLANNED_AT',
  PRODUCT_CATEGORY = 'PRODUCT_CATEGORY',
  PRODUCT_ID = 'PRODUCT_ID',
  PRODUCT_IDS = 'PRODUCT_IDS',
  PROMOTION = 'PROMOTION',
  PROMOTION_STATUS = 'PROMOTION_STATUS',
  REPLACEMENT = 'REPLACEMENT',
  REPLENISHMENT_CATEGORIES = 'CATEGORY',
  REGION = 'REGION',
  SALES_CHANNEL = 'SALES_CHANNEL',
  SALE_PLATFORM = 'SALE_PLATFORM',
  SALE_COUNTRY = 'SALE_COUNTRY',
  SEARCH = 'SEARCH',
  SELL_OUT_IN = 'SELL_OUT_IN',
  SERVICE_LEVELS = 'SERVICE_LEVELS',
  SERVICE_LEVEL_CLASS = 'SERVICELEVEL',
  SHIPPING_DATE = 'SHIPPING_DATE',
  SOURCE = 'SOURCE',
  SOURCE_COUNTRY = 'SOURCE_COUNTRY',
  SOURCE_TYPE = 'SOURCE_TYPE',
  STATUS = 'STATUS',
  TO_ID = 'TO_ID',
  TYPE = 'TYPE',
  USER = 'USER',
  USERS = 'USERS',
  VAT_CODE = 'VAT_CODE',
  VENDOR = 'VENDOR',
  VENDORS = 'VENDORS',
  VENDOR_ID = 'VENDOR_ID',
  VENDOR_TYPE = 'VENDOR_TYPE',
  WAREHOUSE = 'WAREHOUSE',
  WAREHOUSES = 'WAREHOUSES',
  WAREHOUSE_ID_STOCK = 'WAREHOUSE_ID_STOCK',
  LOGS_REGION = 'LOGS_REGION'
}
